import { Link } from '@remix-run/react'

import { Container, Logo } from '~/components'

export const TopHeader = () => {
  return (
    <header aria-label="header">
      <Container className="h-[58px] w-full">
        <Link className="flex h-full items-center gap-x-4" to="/">
          <Logo />
          <h2
            aria-label="header-logo-title"
            className="text-xs font-bold text-brand lg:text-sm"
          >
            シェアオフィス
            <br />
            コワーキングスペース検索サイト
          </h2>
        </Link>
      </Container>
    </header>
  )
}
